<template>
  <div mt="30%" flex="~ col items-center">
    <NuxtIcon name="my-icon:search" w="10" h="10" text="black" />
    <div mt="4" px="10" text="center">
      <div text="base #121212" font="500">
        {{ computedTitle }}
      </div>
      <div mt="3" text="sm #808080" :style="{ wordBreak: 'break-word' }">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
  },
  description: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const computedTitle = computed(() => props.title ?? t('key.search.result.empty.title'))
</script>

<style>

</style>
